import React, { useEffect, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import { userAuthenticatedState } from './store/DatePicker'
import { useRecoilValue,useRecoilState } from 'recoil'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)


// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const ForgotPassword = React.lazy(() => import("./components/ForgotPassword"));
const VerifyOtpAndReset = React.lazy(() => import("./components/VerifyOtpAndReset"));
const Login = React.lazy(() => import('./components/Login'))


function App() {
  // const isAuthenticated = useRecoilValue(userAuthenticatedState)
  // const userRole = localStorage.getItem('userRole');

  const [isAuthenticated, setIsAuthenticated] = useRecoilState(userAuthenticatedState);

  useEffect(() => {
    // Check localStorage for authentication status on initial render
    const storedAuthStatus = localStorage.getItem('userRole');
    if (storedAuthStatus !== null) {
      setIsAuthenticated(storedAuthStatus === 'true');
    }
  }, [setIsAuthenticated]);


  return (
    <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route
              path="*"
              element={isAuthenticated ? <DefaultLayout /> : <Login />}
            />
            <Route exact path="/ForgotPassword" element={<ForgotPassword />} />
            <Route exact path="/VerifyOtpAndReset" element={<VerifyOtpAndReset />} />
          </Routes>
        </Suspense>
      </HashRouter>
  )
}

export default App
